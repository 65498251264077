import React from "react";
import Layout from '../components/Layout'
import Seo from "../components/Seo";

const Page404 = () => {

    function renderBg() {
        return (
            <h1 className="page-heading">Pagina niet gevonden</h1>
        )
    }

    return (
        <Layout>
            <Seo seo={{metaDesc: 'Pagina niet gevonden', title: 'Pagina niet gevonden'}} />
            {renderBg()}
            <div className="container w-5/6 lg:w-full">
                <div className="py-20 text-lg">
                    <div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Page404